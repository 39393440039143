/***** BASE *****/

body{
	margin: 0;
	padding: 0 !important;
	font-size:14px;
	font-family: $sans;
	line-height: 1.5;
	color: $corpo;
	background: #fff;
		@media($smart){
			font-size:12px;
		}
}

.js body{
	@include hide;
	transition: opacity 0.7s ease;
		&.is-ready{
			@include show;
		}
}



h1,h2,h3,h4,h5{
	margin: 0px;
	text-transform: uppercase;
	font-family: $heavy;
	font-weight: normal;
}

h1{
	font-size: 1.75em;
}

h2{
	font-size: 1.75em; 
}

h3{
	font-size: 1.1em;
}

h4{
	font-size: 1em;
}

a{
	opacity: 0.7;
	@include transitions;
		&.active{
			opacity: 1;
		}
		&:hover{
			opacity: 0.8;
		}
}

img,iframe,video{
	max-width: 100%;
	height: auto;
	width: auto;
}

.node-unpublished {
    background-color: transparent; 
}