/***** Front *****/

.front .main-content__inner{
	@include flexbox(center,space-between);
		@media($smart){
			margin-top: 5vh;
		}
}

.front-intro{
	h1{
		font-size: 6em;
		margin-bottom: 10px;
			@media($smart){
				font-size:3.5em;
			}
	}
	p{
		font-size:4em;
		margin: 0;
		font-family: $light;
		line-height: 1.3;
		opacity: 0.8;
			@media($smart){
				font-size:2em;
			}
			b{
				font-family: $heavy;
				font-weight: normal;
				display: inline-block;
				@include transitions($prop:color);
			}
			u:before{
				transition-delay:0.5s;
			}
	}
	span{
		display: inline-block;
		@include transitions($prop:color);
	}
	u{
		display: inline-block;
		text-decoration: none;
		position: relative;
		z-index: 1;
		@include transitions($prop:color);
			&:before{
				content: "";
				display: block;
				position: absolute;
				z-index: -1;
				left: 0;
				bottom: 0;
				height: 0.5em;
				width: 100%;
				opacity: 0.6;
				transform: scaleX(0) skewX(10deg);
				transform-origin: right center 0;
				transition: transform 0.7s cubic-bezier(0.19,1,0.22,1);
			}
			&.intro-done:before{
				transform: scaleX(1) skewX(10deg);
			}
	}
}


//Background Show

.home-show{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	z-index: -1;
	opacity: 0;
	@include transitions;
	background-color: #000;
  &_desktop{
    @media($smart) {
      display: none;
    }
  }
  &_mobile{
    display: none;
    @media($smart) {
      display: block;
    }
  }
}
.home-show-item{
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background:no-repeat center center;
	background-size: cover;
		&__img{
			display: none;
		}
		@media($tablet){
			&:nth-child(6){
				background-position: 80% center;
			}
			&:nth-child(5){
				background-position: left center;
			}
			&:nth-child(3){
				background-position: 80% center;
			}
		}
}

@keyframes bounce{
	0%{
		right:100%;
		left:0;
	}
	7%{
		right:0;
		left:0;
	}
	14%{
		right:0;
		left:100%
	}
	21%{
		right:0;
		left:0;
	}
	28%{
		right:100%;
		left:0;
	}
	100%{
		right:100%;
		left:0;
	}
}
@keyframes pressShow{
	0%{
		transform: translateY(0px);
		opacity: 0;
	}
	15%{
		transform: translateY(-14px);
		opacity: 1;
	}
	30%{
		transform: translateY(-21px);
		opacity: 0;
	}
	100%{
		transform: translateY(0px);
		opacity: 0;
	}
}
@keyframes pressShowHover{
	0%{
		transform: translateY(-10px);
		opacity: 0;
	}
	100%{
		transform: translateY(-21px);
		opacity: 1;
	}
}

.start-show{
	&:after{
		content: "Press & Hold";
		display: block;
		position: absolute;
		font-size: 0.15em;
		top: 0px;
		left: 0;
		width: 100%;
		text-align: center;
		font-family: $sans;
		opacity: 0;
			@media($smart){
				top: 1em;
				font-size: 0.2em;
			}
	}
	em{
		display: block;
		position: absolute;
		height: 0.5em;
		bottom: 0px;
		left: 0;
		background: rgba(#fff,0.5);
		transform: skew(10deg);
		right: 100%;
		z-index: -1;
	}
	&.is-active{
		user-select:none;
		cursor: pointer;
			&:before{
				transition: all 0.7s cubic-bezier(0.19,1,0.22,1);
			}
			&:after{
				animation: pressShow 6s infinite linear 2s;
			}
			em{
				animation: bounce 6s infinite ease 2s;
			}
			&:hover{
				animation:none;
					&:before{
						bottom:-5px;
						opacity: 1;
					}
					&:after{
						animation: pressShowHover 0.7s 1 ease forwards;
					}
					em{
						animation:none;
					}
			}
	}
	.start-show-hover{
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		display: block;
	}
}

body.show-started{
	//background-color: #000;
		& .header{
			background-color: transparent;
				ul.menu li:not(:first-child){
					opacity: 0;
				}
				ul.menu li:first-child a{
					color: #fff;
					transition:none;
						&:after{
							display: none;
						}
				}
		}
		.color-switch{
			@include hide;
		}
		.front-intro span, .front-intro b{
			color:transparent;
		}
		.front-intro p{
			opacity: 1;
		}
		.front-intro u{
			color: #fff;
		}
		.home-show{
			@include show;
		}
		.start-show:after{
			opacity: 0 !important;
		}
		.start-show em{
			animation:none;
			bottom: -5px;
		}
}
