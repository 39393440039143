/***** FORM *****/

label{
	font-size:0.8em; 
}

textarea, select, input[type="date"],
input[type="datetime"], input[type="datetime-local"],
input[type="email"], input[type="month"],
input[type="number"], input[type="password"],
input[type="search"], input[type="tel"],
input[type="text"], input[type="time"],
input[type="url"], input[type="week"] {
  height: auto;
  width: 100%;
  padding: 6px;
  box-sizing: border-box;
  border: 1px solid rgba($corpo,0.2);
  background:#fff;
  border-radius:3px;
  box-shadow:none; 
  font-family: $sans;
  font-size: 1em;
  appearance: none;
  @include transitions;
    &.input-error{
      border-color:#cc0000;
    }
}

select{
  background:url("../images/select-arrow.svg") no-repeat 97% center;
}

.msg-error{
  font-size: 0.7em;
  color:#cc0000;
}

input:focus, input:active, select:focus, select:active, textarea:focus, textarea:active {
  border-color: $corpo;
  z-index: 1;
}

textarea{
  resize:none;
}

.form-item .description {
	font-size: 0.7em;
	margin-top: 3px;
}

.form-item, .form-actions {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    position: relative;
}

.field-suffix {
    position: absolute;
    right: 15px;
    line-height: 1;
    top: 34px;
    font-size: 0.9em;
    color: rgba($corpo,0.5);
}



