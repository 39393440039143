/***** Portfolio menu ******/

.menu-portfolio{
	text-transform: uppercase;
	line-height: 2em;
		li{
			white-space: nowrap;
			text-overflow:ellipsis;
			overflow: hidden;
				a{
					position: relative;
					z-index: 1;
						&:before{
							content: "";
							display: block;
							position: absolute;
							z-index: -1;
							left: 0;
							bottom: -0.2em;
							height: 0.8em;
							width: 100%;
							opacity: 0.4;
							transform: scaleX(0);
							transform-origin: right center 0;
							transition: transform 0.7s cubic-bezier(0.19,1,0.22,1);
						}
						&:hover:before, &.active:before{
							transform: scaleX(1);
							transform-origin: left center 0;
						}
				}
		}
}

/*** Portfolio Mobile ***/

.view-portfolio .work-item{
	margin-bottom: 2em;
		h3{
			font-family: $sans;
			margin-top: 0.5em;
		}
		a{
			opacity: 1;
		}
}

