/***** Main menu ******/

.header__inner{
  @media($tablet){
    @include flexbox(center,space-between);
  }
}

.menu-principale{
	text-transform: uppercase;
	line-height: 2em;
  flex-grow: 0.7;
		ul.menu{
			@media($tablet){
        @include flexbox(center,space-between);
			}
		}
}

.home-link{
	opacity: 1;
	display: inline-block;
	position: relative;
		@media($tablet){
			display: inline;
		}
		&:after{
			content: "SHOWMAKER";
			display: inline-block;
			opacity: 0.3;
			margin-left: 8px;
			padding-left: 8px;
			border-left: 1px solid rgba($corpo,0.3);
				@media($tablet){
					display: none;
				}
		}
		&:before{
			content: "";
			position: absolute;
			z-index: -1;
			left: 0;
			bottom: -0.2em;
			height: 0.8em;
			width: 100%;
			opacity: 0.4;
			transform: scaleX(0);
			transform-origin: right center 0;
			transition: transform 0.7s cubic-bezier(0.19,1,0.22,1);
			display: none;
				@media($tablet){
					display: block;
				}
		}
		&:hover:before, &.active:before{
			transform: scaleX(1);
			transform-origin: left center 0;
		}
}


.about-link, .portfolio-link, .reel__trigger{
	position: relative;
	z-index: 1;
		&:before{
			content: "";
			display: block;
			position: absolute;
			z-index: -1;
			left: 0;
			bottom: -0.2em;
			height: 0.8em;
			width: 100%;
			opacity: 0.4;
			transform: scaleX(0);
			transform-origin: right center 0;
			transition: transform 0.7s cubic-bezier(0.19,1,0.22,1);
		}
		&:hover:before, &.active:before{
			transform: scaleX(1);
			transform-origin: left center 0;
		}
}

/*.node-type-work .portfolio-link{
	opacity: 1;
	&:before{
		transform: scaleX(1);
		transform-origin: left center 0;
	}
}*/

#block-views-reel-block{
  @media($tablet) {
    @include flexbox(center,flex-start);
  }
}

.reel{
  &__trigger{
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 0.25rem;
    @include flexbox(center,flex-start);
    display: inline-flex;
    @media($smart) {
      &:before{
        content: "Reel";
        position: static;
        margin-right: 0.25rem;
        width: auto;
        height: auto;
        background: none !important;
        opacity: 1;
        transform: none;
        line-height: 1rem;
        font-size: 0.75rem;
        text-transform: uppercase;
      }
      span{
        display: none;
      }
    }
    svg{
      height: 0.8rem;
      width: 0.6rem;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      background: url('../images/reel-play.svg') no-repeat center center;
      background-size: 100% auto;
      margin-left: 0.5rem;
      margin-top: -1px;
      @media($smart){
        background-image: url('../images/cursor-play.svg');
        margin-left: 0;
      }
    }

  }
  &__container{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba($dark-grey,0.8);
    @include hide;
    @include transitions;
    &__video{
      position: absolute;
      left: 50%;
      top: 50%;
      width: 70vw;
      transform: translate(-50%,-50%);
      height: auto;
      @media($tablet) {
        width: 90vw;
      }
    }
    &__close{
      position: absolute;
      top: 2.5rem;
      left: 50%;
      transform: translateX(-50%);
      width: 2.5rem;
      height: 2.5rem;
      z-index: 99;
      cursor: pointer;
      &:after,&:before{
        content: "";
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        top: 50%;
        background-color: $white;
        @include transitions;
      }
      &:after{
        transform: rotate(-45deg);
      }
      &:before{
        transform: rotate(45deg);
      }
      &:hover{
        &:after{
          transform: rotate(-135deg);
        }
        &:before{
          transform: rotate(135deg);
        }
      }
    }
    &.is-active{
      @include show;
    }
  }
}
