/***** INIT *****/


//FONTS
@font-face {
    font-family: 'cooperhewitt-book';
    src: url('../font/cooperhewitt-book.eot');
    src: url('../font/cooperhewitt-book.eot?#iefix') format('embedded-opentype'),
         url('../font/cooperhewitt-book.woff2') format('woff2'),
         url('../font/cooperhewitt-book.woff') format('woff'),
         url('../font/cooperhewitt-book.svg#cooper_hewitt_book') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'cooperhewitt-heavy';
    src: url('../font/cooperhewitt-heavy.eot');
    src: url('../font/cooperhewitt-heavy.eot?#iefix') format('embedded-opentype'),
         url('../font/cooperhewitt-heavy.woff2') format('woff2'),
         url('../font/cooperhewitt-heavy.woff') format('woff'),
         url('../font/cooperhewitt-heavy.svg#cooper_hewitt_heavy') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'cooperhewitt-light';
    src: url('../font/cooperhewitt-light.eot');
    src: url('../font/cooperhewitt-light.eot?#iefix') format('embedded-opentype'),
         url('../font/cooperhewitt-light.woff2') format('woff2'),
         url('../font/cooperhewitt-light.woff') format('woff'),
         url('../font/cooperhewitt-light.svg#cooper_hewitt_light') format('svg');
    font-weight: normal;
    font-style: normal;
}

$sans:'cooperhewitt-book', sans-serif;
$light:'cooperhewitt-light', sans-serif;
$heavy:'cooperhewitt-heavy', sans-serif; 

//DEVICE
$thin: "max-height:650px";
$desktop: "min-width: 981px";
$bigscreen: "min-width:1600px";
$smallscreen: "max-width:1200px";
$tablet: "max-width:980px";
$smart: "max-width:740px";

//COLORS
$white:#F6F6F6;
$corpo:#4d4c4c;
$dark-grey:#333333;
$fucsia:#FF0098;
$orange:#F39200;
$fucsia-2:#E6007E;
$azzurro: #00A0E4;
$flash-green: #27C968;
$purple-flash: #C000FF;
$verde-acqua: #00A19A;
$dark-blue: #1D71B8;
$yellow-flash: #FCEA10;
$red: #BC173F;
$caghetta:#BFBA00;
$super-green:#AAFF9F;
$purple: #82209E;
$dark-purple: #623C93;
$yellow-smooth: #FFF63B;


//GRADIENTS
@mixin onWhite{
	background: $fucsia;
	background: linear-gradient(to right, $orange 0%, $fucsia 100%);
}
@mixin onBlack{
	background: $azzurro;
	background: linear-gradient(to right, $azzurro 0%, $fucsia-2 100%);
}
@mixin onGreen{
    background: $purple-flash;
    background: linear-gradient(to right, $purple-flash 0%, $verde-acqua 100%);
}
@mixin onBlue{
    background: $verde-acqua;
    background: linear-gradient(to right, $yellow-flash 0%, $verde-acqua  100%);
}
@mixin onCiano{
    background: $yellow-flash;
    background: linear-gradient(to right, $yellow-flash 0%, $verde-acqua 100%);
}
@mixin onRed{
    background: $orange;
    background: linear-gradient(to right, $orange 0%, $fucsia 100%);
}
@mixin onPurple{
    background: $purple-flash;
    background: linear-gradient(to right, $fucsia 0%, $purple-flash 100%);
}
@mixin onYellow{
    background: $purple-flash;
    background: linear-gradient(to right, $yellow-smooth 0%, $purple-flash 100%);
}














