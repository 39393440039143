/******** Portfolio Misc *******/

.field-name-field-ruolo{
	text-transform: uppercase;
	opacity: 0.5;
}

.work-description{
	margin-top: 60px;
	position: relative;
	z-index: 1;
		@media($tablet){
			margin: 30px 0 0;
		}
		&__content{
			top: 1.5em;
			padding: 20px 0;
			position: absolute;
			left: 0;
			width: 100%;
			z-index: 50;
			display: none;
				@media($smart){
					max-height: 50vh;
					overflow: auto;
				}
				a{
					border-bottom: 1px solid;
				}
		}
		&__toggle{
			position: relative;
			float: left;
			min-width: 200px;
			text-transform: uppercase;
			padding-left: 13px;
			opacity: 0.5;
			cursor: pointer;
			line-height: 1.5em;
			height: 1.5em;
			overflow: hidden;
				&:hover{
					opacity: 0.7;
				}
				&:before{
					content: "+";
					position: absolute;
					left: 0;
					line-height: 1.5em;
					display: block;
					@include transitions;
				}
				span{
					position: absolute;
					left: 13px;
					width: 100%;
					@include transitions;
				}
				&__open{
					top: 0;
					opacity: 1;
				}
				&__close{
					top:-1.5em;
					opacity: 0;
				}
				&.is-active{
					.work-description__toggle__open{
						top:1.5em;
						opacity: 0;
					}
					.work-description__toggle__close{
						top:0;
						opacity: 1;
					}
					&:before{
					 transform:rotate(45deg);
					}
				}
		}
}

.prevnext{
	display: none;
		@media($tablet){
			display: block;
			margin-right: 10px;
			text-transform: uppercase;
		}
}

.page-node-109 .prevnext{
  display: none;
  @media($tablet){
    display: none;
  }
}

.prev-next-work{
	display: none;
		@media($tablet){
			@include flexbox(center,flex-start);
			padding: 0 10px;
			box-sizing:border-box;
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 40px;
			background: #fff;
			border-top:1px solid rgba($corpo,0.2);
			z-index: 99;
		}
}
