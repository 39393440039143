/**** MIXIN *****/

//RESPONSIVE IMAGE
@mixin responsive{
  max-width:100%;
  height:auto;
  display:block;
}

//TRANSITIONS
@mixin transitions($prop:all, $sec:0.35s, $func:ease-out, $del:0s){
  -webkit-transition: $prop $sec $func $del;
  transition: $prop $sec $func $del;
}

//HIDE & SHOW
@mixin hide{
  opacity:0;
  visibility:hidden;
}
@mixin show{
  opacity:1;
  visibility:visible;
}

//Clearfix
@mixin clearfix{
  &:after{
    content: "";
    clear: both;
    display: block;
  }
}

//FLEXBOX
@mixin flexbox($align:unset, $justify:unset, $direction:unset, $wrap:unset){
  display:flex;
  align-items:$align;
  justify-content:$justify;
  flex-direction:$direction;
  flex-wrap:$wrap;
}