/****** Cursor *****/

*{
	//cursor: none !important;
}

.cursor{
	position:fixed;
	width: 10px;
	height: 10px;
	margin-top: -3px;
	margin-left: -3px;
	background: rgba(#000,0.5);
	z-index: 999;
	transform:rotate(45deg);
	pointer-events:none;
		@media($tablet){
			display: none;
		}
		span{
			position: absolute;
			width: 60px;
			height: 60px;
			left: 50%;
			top: 50%;
			margin-top: -30px;
			margin-left: -30px;
			border:1px solid rgba(#000,0.2);
			background: rgba(#fff,0.2);
			border-radius: 100%;
			z-index: -1;
			pointer-events:none;
			transform:scale(0);
			opacity: 0;
			@include transitions;
		}
		&.is-hover span{
			transform:scale(1);
			opacity: 1;
		}
}