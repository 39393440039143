/************ COLORS **************/

@keyframes coltext{
	0%{
		transform: translateX(10px);
		opacity: 0;
	}
	15%{
		transform: translateX(-18px);
		opacity: 0.7;
	}
	30%{
		transform: translateX(0px);
		opacity: 0;
	}
	100%{
		transform: translateX(0px);
		opacity: 0;
	}
}
@keyframes colwow{
	0%{
		transform: translateX(0px);
		opacity: 1;
	}
	100%{
		transform: translateX(-10px);
		opacity: 0;
	}
}
@keyframes colred{
	0%{
		transform: translateX(0px);
		opacity: 0;
	}
	15%{
		transform: translateX(-8px);
		opacity: 1;
	}
	30%{
		transform: translateX(0px);
		opacity: 0;
	}
	100%{
		transform: translateX(0px);
		opacity: 0;
	}
}
@keyframes colgreen{
	0%{
		transform: translateX(0px);
		opacity: 0;
	}
	15%{
		transform: translateX(-8px);
		opacity: 1;
	}
	30%{
		transform: translateX(0px);
		opacity: 0;
	}
	100%{
		transform: translateX(0px);
		opacity: 0;
	}
}
@keyframes colblue{
	0%{
		transform: translateX(0px);
		opacity: 0;
	}
	15%{
		transform: translateX(-16px);
		opacity: 1;
	}
	30%{
		transform: translateX(0px);
		opacity: 0;
	}
	100%{
		transform: translateX(0px);
		opacity: 0;
	}
}
@keyframes colborder{
	0%{
		opacity: 0.5;
	}
	15%{
		opacity: 0;
	}
	30%{
		opacity: 0.5;
	}
	100%{
		opacity: 0.5;
	}
}

.color-switch{
	position: fixed;
	top:30px;
	right: 30px;
	z-index: 100;
		@media($tablet){
			top:auto;
			bottom: 10px;
			right:10px;
		}
		&__text{
			display: inline-block;
			vertical-align: middle;
			margin-right: 10px;
			animation: coltext 7s infinite ease;
			animation-delay:2s;
			text-transform: uppercase;
			font-size: 0.9em;
			opacity: 0;
		}
		&.is-switched .color-switch__text{
			opacity: 0;
			animation: colwow 2s ease;
			animation-delay:0s;
		}
		&__icon{
			display: inline-block;
			vertical-align: middle;
			width: 25px;
			height: 25px;
			border-radius: 100%;
			cursor: pointer;
			position: relative;
				@media($smart){
					height: 20px;
					width: 20px;
				}
				em{
					position: absolute;
					width: 100%;
					height: 100%;
					left: 0;
					top: 0px;
					@include onBlack;
					border-radius: 100%;
					opacity: 0;
					//mix-blend-mode: lighten;
					animation: colred 7s infinite ease;
					animation-delay:2s;
					z-index: 0;
				}
				em:after,em:before{
					content: "";
					position: absolute;
					width: 100%;
					height: 100%;
					bottom:0;
					border-radius: 100%;
					opacity: 0;
					//mix-blend-mode: lighten;
				}
				em:after{
					@include onGreen;
					left: 0;
					animation: colgreen 7s infinite ease;
					animation-delay:2s;
					z-index: 1;
				}
				em:before{
					@include onWhite;
					right: 0;
					animation: colblue 7s infinite ease;
					animation-delay:2s;
					z-index: 2;
				}
		}
		&__icon:after, &__icon:before{
			content: "";
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			border-radius: 100%;
			box-sizing:border-box;
		}
		&__icon:after{
			border:2px solid;
			animation: colborder 7s infinite ease;
			animation-delay:2s;
			opacity: 0.5;
		}
		&__icon:before{
			transition:all 0.5s ease;
			z-index: -1;
			background-color: rgba(#000,0.1);
			transform:scale(0);
		}
		&__icon:hover:before{
			transform:scale(2);
		}
		&.switching .color-switch__icon:after{
			transform:scale(10);
			opacity: 0;
			transition:all 0.9s cubic-bezier(1.000, 0.000, 0.000, 1.000);
			z-index: 100;
		}
		&.is-switched .color-switch__icon:after{
			animation:none;
		}
		&.is-switched .color-switch__icon em{
			display: none;
		}
}


body.white{
	background-color: $white;
	color:$corpo;
    .reel__trigger svg path{
      fill: $corpo;
    }
		a{
			color: $corpo;
		}
		.header{
			background-color: $white;
		}
		.work-description__content, .prev-next-work{
			background-color: $white;
		}
		.menu-portfolio li a:before, .menu-principale li a:before, .menu-about li span:before, .front-intro u:before, .reel__trigger:before{
			@include onWhite;
		}
		.color-switch .color-switch__icon:after{
			border-color: $corpo;
		}

}

body.dark{
	background-color: $dark-grey;
	color:#fff;
  .reel__trigger svg path{
    fill: #fff;
  }
		a{
			color: #fff;
		}
		.header{
			background-color: $dark-grey;
		}
		.work-description__content, .prev-next-work{
			background-color: $dark-grey;
		}
		.menu-portfolio li a:before, .menu-principale li a:before, .menu-about li span:before, .front-intro u:before, .reel__trigger:before{
			@include onBlack;
		}
		.color-switch .color-switch__icon:after{
			border-color: #fff;
		}
		.color-switch .color-switch__icon:before{
			background-color: rgba(#fff,0.1);
		}
		.cursor{
			background: rgba(#fff,0.8);
				span{
					border-color: rgba(#fff,0.2);
					background-color: rgba($dark-grey,0.2);
				}
		}
		.swiper-button-black{
			filter:invert(1);
		}
}

body.green{
	background-color: $flash-green;
	color:#fff;
  .reel__trigger svg path{
    fill: #fff;
  }
		a{
			color:#fff;
		}
		.header{
			background-color: $flash-green;
		}
		.work-description__content, .prev-next-work{
			background-color: $flash-green;
		}
		.menu-portfolio li a:before, .menu-principale li a:before, .menu-about li span:before, .front-intro u:before, .reel__trigger:before{
			@include onGreen;
		}
		.color-switch .color-switch__icon:after{
			color:#fff;
		}
		.color-switch .color-switch__icon:before{
			background-color: rgba(#fff,0.1);
		}
		.cursor{
			background: rgba(#fff,0.8);
				span{
					border-color: rgba(#fff,0.2);
					background-color: rgba($flash-green,0.2);
				}
		}
		.swiper-button-black{
			filter:invert(1);
		}
}

body.blue{
	background-color: $dark-blue;
	color:#fff;
  .reel__trigger svg path{
    fill: #fff;
  }
		a{
			color: #fff;
		}
		.header{
			background-color: $dark-blue;
		}
		.work-description__content, .prev-next-work{
			background-color: $dark-blue;
		}
		.menu-portfolio li a:before, .menu-principale li a:before, .menu-about li span:before, .front-intro u:before, .reel__trigger:before{
			@include onBlue;
		}
		.color-switch .color-switch__icon:after{
			border-color:  #fff;
		}
		.color-switch .color-switch__icon:before{
			background-color: rgba(#fff,0.1);
		}
		.cursor{
			background: rgba(#fff,0.8);
				span{
					border-color: rgba(#fff,0.2);
					background-color: rgba($dark-blue,0.2);
				}
		}
		.swiper-button-black{
			filter:invert(1);
		}
}
body.red{
	background-color: $red;
	color:#fff;
  .reel__trigger svg path{
    fill: #fff;
  }
		a{
			color: #fff;
		}
		.header{
			background-color: $red;
		}
		.work-description__content, .prev-next-work{
			background-color: $red;
		}
		.menu-portfolio li a:before, .menu-principale li a:before, .menu-about li span:before, .front-intro u:before, .reel__trigger:before{
			@include onRed;
		}
		.color-switch .color-switch__icon:after{
			border-color: #fff;
		}
		.color-switch .color-switch__icon:before{
			background-color: rgba(#fff,0.1);
		}
		.cursor{
			background: rgba(#fff,0.8);
				span{
					border-color: rgba(#fff,0.2);
					background-color: rgba($red,0.2);
				}
		}
		.swiper-button-black{
			filter:invert(1);
		}
}

body.cyan{
	background-color: $caghetta;
	color:#fff;
  .reel__trigger svg path{
    fill: #fff;
  }
		a{
			color: #fff;
		}
		.header{
			background-color: $caghetta;
		}
		.work-description__content, .prev-next-work{
			background-color: $caghetta;
		}
		.menu-portfolio li a:before, .menu-principale li a:before, .menu-about li span:before, .front-intro u:before, .reel__trigger:before{
			@include onCiano;
		}
		.color-switch .color-switch__icon:after{
			border-color: #fff;
		}
		.color-switch .color-switch__icon:before{
			background-color: rgba(#fff,0.1);
		}
		.cursor{
			background: rgba(#fff,0.8);
				span{
					border-color: rgba(#fff,0.2);
					background-color: rgba($caghetta,0.2);
				}
		}
		.swiper-button-black{
			filter:invert(1);
		}
}

body.purple{
	background-color: $purple;
	color:$super-green;
  .reel__trigger svg path{
    fill: $super-green;
  }
		a{
			color: $super-green;
		}
		.header{
			background-color: $purple;
		}
		.work-description__content, .prev-next-work{
			background-color: $purple;
		}
		.menu-portfolio li a:before, .menu-principale li a:before, .menu-about li span:before, .front-intro u:before, .reel__trigger:before{
			@include onPurple;
		}
		.color-switch .color-switch__icon:after{
			border-color: $super-green;
		}
		.color-switch .color-switch__icon:before{
			background-color: rgba($super-green,0.1);
		}
		.cursor{
			background: rgba($super-green,0.8);
				span{
					border-color: rgba($super-green,0.2);
					background-color: rgba($purple,0.2);
				}
		}
		.swiper-button-black{
			filter:invert(1);
		}
}
body.yellow{
	background-color: $yellow-smooth;
	color:$dark-purple;
  .reel__trigger svg path{
    fill: $dark-purple;
  }
		a{
			color: $dark-purple;
		}
		.header{
			background-color: $yellow-smooth;
		}
		.work-description__content, .prev-next-work{
			background-color: $yellow-smooth;
		}
		.menu-portfolio li a:before, .menu-principale li a:before, .menu-about li span:before, .front-intro u:before, .reel__trigger:before{
			@include onYellow;
		}
		.color-switch .color-switch__icon:after{
			border-color: $dark-purple;
		}
		.color-switch .color-switch__icon:before{
			background-color: rgba($dark-purple,0.1);
		}
		.cursor{
			background: rgba($dark-purple,0.8);
				span{
					border-color: rgba($dark-purple,0.2);
					background-color: rgba($yellow-smooth,0.2);
				}
		}
}








