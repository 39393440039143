/***** LAYOUT *****/

.clearfix::after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

body.no-scroll{
	overflow: hidden;
}

.barba-wrapper{
	overflow: hidden;
}


.header{
	position: fixed;
	left: 0;
	top: 0;
	z-index: 5;
	height: 150px;
	padding:30px 0 0 30px;
	width: 20%;
	box-sizing:border-box;
		@media($smallscreen){
			width: 25%;
		}
		@media($tablet){
			height: auto;
			width: 100%;
			padding: 20px;
			border-bottom:1px solid rgba($corpo,0.2);
		}
}

body #barba-wrapper .front header{
	background:transparent;
	height: 100vh;
		@media($tablet){
			height: auto;
		}
}

.side{
	padding:30px;
	margin-top: 150px;
	width: 20%;
	box-sizing:border-box;
	min-height: calc(100vh - 150px);
		@media($smallscreen){
			width: 25%;
		}
		@media($tablet){
			display: none;
		}
		.sidebar{
			width: auto;
		}
}



.main-content{
	position: fixed;
	top: 0;
	left: 20%;
	height: 100vh;
	width: 80%;
	padding: 30px;
	box-sizing:border-box;
	overflow-y: auto;
		@media($smallscreen){
			width: 75%;
			left: 25%;
		}
		@media($tablet){
			position: static;
			width: 100%;
			padding: 90px 20px 20px;
			height: auto;
			min-height: 100vh; // Provvisorio
		}
		&__inner{
			min-height: 100%;
		}
}

.node-content{
	position: relative;
}

.node-type-work{
	.main-content__inner{
		//@include flexbox(space-between,space-between,column);
	}
}