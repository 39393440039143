/***** OVERRIDE BASE THEME *****/

/* Generic */

.node, .comment, .block {
  margin-bottom: 0;
}

ul, ol, .item-list ul {
    padding-left: 0;
    margin-bottom: 0;
}

ul li, ol li{
  list-style: none;
  margin: 0;
}
li, .item-list ul li, ul.menu li {
    margin-top: 0;
    margin-bottom: 0;
}

/* Form */

.description {
  color: rgba($corpo,0.5);
}

/* Fieldsets */

fieldset .fieldset-legend {
  margin:0;
  padding:0;
  left:0;
  width: 100%;
  text-transform: none;
  box-sizing:border-box; 
}

legend {
  font-weight: 800;
}

fieldset {
  border: none;
  padding: 0;
  position: relative;
  margin: 0;
}

fieldset .fieldset-wrapper {
  padding: 0;
}

html.js fieldset.collapsed {
  border-width: 0;
  height: auto;
}

fieldset.collapsed {
  background-color: transparent;
}

a.fieldset-title {
  text-decoration: none;
  vertical-align: middle;
}

/* Horizontal tabs */

div.horizontal-tabs {
    margin: 0;
    padding: 0;
    border: none;
    position: relative;
    	fieldset.horizontal-tabs-pane {
    		padding:0;
    	}
}