/***** Foooter *****/

.footer-info{
	position: fixed;
	right: 30px;
	bottom: 20px;
	font-size: 0.85em;
	opacity: 0.6;
		@media($tablet){
			position: static;
			font-size: 0.7em;
		}
		@media($smart){
			display: none;
		}
		p{
			margin:0;
		}
}