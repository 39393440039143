/***** Toolbar ******/

.toggleToolbar{
  position:fixed;
  bottom:0;
  right:0;
  width:50px;
  height:60px;
  background:url("../images/admin.png") no-repeat center center $corpo;
  background-size:90%;
  cursor:pointer;
  z-index:100;
    &:hover{
      background-color:$corpo;
    }
    @media($tablet){
      width:35px;
      height:35px;
    }
}

div#toolbar {
    background: rgba($corpo, 0.8);
    box-shadow: none;
    color: #fff;
    font: 16px $sans;
    font-weight:400;
    left: 0;
    margin: 0 50px 0 0;
    padding: 0;
      @media($tablet){
        margin-right:35px;
      }
      div.toolbar-menu {
        background:$corpo;
        padding:5px 10px;
          ul li a{
            border-radius:0;
              &:hover{
                background-color:rgba(#000,0.2);
              }
          }
      }
      a.toggle{
        display:none;
      }
      div.toolbar-shortcuts ul li a{
        border-radius: 0;
          &:hover{
            background-color:rgba(#000,0.2);
          }
      }
      #toolbar-home li a{
        background:none;
      }
}

