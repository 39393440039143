/***** Portfolio Media *****/

.work-media{
	margin-top: 20px;
  margin-bottom: 80px;
  position: relative;
	img{
		width: auto;
		height: auto;
		max-width: 100%;
		max-height: 65vh;
			@media($thin) and ($desktop){
				max-height: 55vh;
			}
	}
	.swiper-wrapper{
		@media($tablet){
			display: block;
		}
	}
	.swiper-slide{
			@media($tablet){
				margin-bottom: 1em;
			}
	}
	.swiper-navigation{
		position: absolute;
		bottom: -2.5em;
		font-size: 0.8em;
		left: 0;
		width: 130px;
		@include flexbox(center,space-between);
		//display: none;
			@media($tablet){
				display: none;
			}
	}
	.swiper-pagination{
		position: static;
		opacity: 0.6;
	}
	.swiper-button-next,.swiper-button-prev {
		position:static;
		width:1.5em;
		height:1.5em;
		margin-top:0;
		z-index:10;
		cursor:pointer;
		background-size:auto 100%;
		background-position:center;
		background-repeat:no-repeat;
		outline: none;
			&:hover{
				opacity: 0.6;
			}
	}
	.swiper-button-next.swiper-button-disabled,.swiper-button-prev.swiper-button-disabled {
		opacity:.35;
		cursor:auto;
		pointer-events:none;
	}
	.swiper-button-prev,.swiper-container-rtl .swiper-button-next {
		left:10px;
		right:auto;
	}
	.swiper-button-next,.swiper-container-rtl .swiper-button-prev {
		right:10px;
		left:auto;
	}
}

.embed-holder {
  overflow: hidden;
  position: relative;
  height: 65vh;
  width: 115vh;
  	@media($smallscreen){
  		height: 50vh;
  		width: 90vh;
  	}
  	@media($tablet){
  		height: 60vw;
  		width: 100%;
  	}
}

.embed-holder iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.work-media--video + .swiper-container{
  margin-top: 40px;
}
